<template>
  <div v-if="loaded" class="relative lg:grid lg:grid-cols-12">
    <div class="col-span-12 text-right font-bold text-2xl flex justify-between bg-gray-100">
      <div class="px-3 font-normal">{{ userFullname }}</div>
      <div @click="$emit('cancel')" class="cursor-pointer px-3">&times;</div>
    </div>
    <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3 bg-gray-200">
      <nav>
        <a
          @click.prevent="setSection('profile')"
          href="#profile"
          class="text-gray-900 hover:text-gray-900 hover:bg-gray-100 group px-3 py-2 flex items-center text-sm font-medium"
          :class="{ 'bg-white text-indigo-700 hover:text-indigo-700 hover:bg-white': section === 'profile' }"
          aria-current="page"
        >
          <svg
            class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
            :class="{ 'text-indigo-500 group-hover:text-indigo-500': section === 'profile' }"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span class="truncate">{{ $t("admin.user.general") }}</span>
        </a>

        <a
          v-if="userdata.id"
          @click.prevent="setSection('relations')"
          href="#relations"
          class="text-gray-900 hover:text-gray-900 hover:bg-gray-100 group px-3 py-2 flex items-center text-sm font-medium"
          :class="{ 'bg-white text-indigo-700 hover:text-indigo-700 hover:bg-white': section === 'relations' }"
        >
          <svg
            class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
            :class="{ 'text-indigo-500 group-hover:text-indigo-500': section === 'relations' }"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <span class="truncate">{{ $t("admin.user.units") }} / {{ $t("admin.user.groups") }}</span>
        </a>
        <a
          v-if="userdata.id"
          @click.prevent="setSection('activity')"
          href="#activity"
          class="text-gray-900 hover:text-gray-900 hover:bg-gray-100 group px-3 py-2 flex items-center text-sm font-medium"
          :class="{ 'bg-white text-indigo-700 hover:text-indigo-700 hover:bg-white': section === 'activity' }"
        >
          <svg
            class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
            :class="{ 'text-indigo-500 group-hover:text-indigo-500': section === 'activity' }"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
            />
          </svg>
          <span class="truncate">{{ $t("admin.user.activity") }}</span>
        </a>
      </nav>
    </aside>

    <div class="space-y-4 sm:px-6 lg:px-0 lg:col-span-9 h-full">
      <validation-observer :ref="'formobserver'" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(save)" action="#" method="POST" autocomplete="off">
          <div v-if="section === 'profile'" class="bg-white py-2 space-y-2 h-full relative px-4">
            <div class="grid grid-cols-4 gap-y-2 gap-x-4">
              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="first_name"
                  v-slot="validation"
                  rules="required"
                  :name="$t('admin.user.first_name')"
                >
                  <label for="fn" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.first_name")
                  }}</label>
                  <input
                    v-focus
                    v-model="userdata.first_name"
                    type="text"
                    name="fn"
                    id="fn"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="last_name"
                  v-slot="validation"
                  rules="required"
                  :name="$t('admin.user.last_name')"
                >
                  <label for="ln" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.last_name")
                  }}</label>
                  <input
                    v-model="userdata.last_name"
                    type="text"
                    name="ln"
                    id="ln"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="pid"
                  v-slot="validation"
                  :rules="{ regex: /\d{12}|\d{8}-\d{4}$/ }"
                  :name="$t('admin.user.pid')"
                >
                  <label for="pid" class="block text-sm font-medium text-gray-700">{{ $t("admin.user.pid") }}</label>
                  <input
                    v-model="userdata.pid"
                    type="text"
                    name="pid"
                    id="pid"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="email"
                  v-slot="validation"
                  rules="required|email"
                  :name="$t('admin.user.email')"
                >
                  <label for="email" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.email")
                  }}</label>
                  <input
                    v-model="userdata.email"
                    type="email"
                    name="ue"
                    id="ue"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider ref="mobile_phone" v-slot="validation" :name="$t('admin.user.mobile_phone')">
                  <label for="mobile_phone" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.mobile_phone")
                  }}</label>
                  <input
                    v-model="userdata.mobile_phone"
                    type="text"
                    name="ump"
                    id="ump"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider ref="hsa_id" v-slot="validation" :name="$t('admin.user.hsaid')">
                  <label for="hsa_id" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.hsaid")
                  }}</label>
                  <input
                    v-model="userdata.hsa_id"
                    type="text"
                    name="hsa_id"
                    id="hsa_id"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="main_unit"
                  v-slot="validation"
                  rules="required"
                  :name="$t('admin.user.main_unit')"
                >
                  <label for="main_unit" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.main_unit")
                  }}</label>
                  <cm-search-select
                    :options="userUnitlist"
                    ref="main_unit"
                    name="name"
                    valueProp="id"
                    id="main_unit"
                    v-model="userdata.main_unit"
                    :selected="userdata.main_unit"
                    @select="userdata.main_unit = $event"
                    :placeholder="$t('admin.user.unit.select')"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4" v-if="selfedit">
                <validation-provider
                  ref="current_password"
                  vid="password_current"
                  v-slot="validation"
                  rules="required"
                  :name="$t('admin.user.current_password')"
                >
                  <label for="current_password" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.current_password")
                  }}</label>
                  <input
                    v-model="userdata.current_password"
                    type="password"
                    name="current_password"
                    id="current_password"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="password"
                  vid="password"
                  v-slot="validation"
                  :rules="passwordRules"
                  :name="$t('admin.user.password')"
                >
                  <label for="password" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.password")
                  }}</label>
                  <input
                    v-model="userdata.password"
                    type="password"
                    name="password"
                    id="password"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>

              <div class="col-span-4 sm:col-span-4">
                <validation-provider
                  ref="password_confirm"
                  v-slot="validation"
                  :rules="passwordRules + `|password:@password`"
                  :name="$t('admin.user.password_confirm')"
                >
                  <label for="password_confirm" class="block text-sm font-medium text-gray-700">{{
                    $t("admin.user.password_confirm")
                  }}</label>
                  <input
                    v-model="password_confirm"
                    type="password"
                    name="password_confirm"
                    id="password_confirm"
                    autocomplete="off"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                    {{ validation.errors[0] }}
                  </p>
                </validation-provider>
              </div>
            </div>

            <div class="flex justify-end space-x-2 mt-2 pt-2">
              <cm-button
                @click.prevent="$emit('cancel')"
                type="button"
                class="flex justify-center py-2 px-4 border border-gray-200 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:border-gray-300 focus:ring-gray active:bg-gray-100 transition duration-150 ease-in-out"
              >
                {{ $t("button.cancel") }}
              </cm-button>
              <cm-button type="submit" :disabled="invalid" visual="success">
                {{ $t("button.save") }}
              </cm-button>
              <cm-button v-if="userdata.id && !selfedit" @click.prevent="userDelete" visual="danger">
                {{ $t("button.delete") }}
              </cm-button>
            </div>
          </div>

          <div v-if="section === 'relations'" class="pl-4">
            <div class="table-row-group font-semibold">
              <div class="table-row">
                <span class="table-cell">{{ $t("admin.user.unit.user_units") }}</span>
                <span class="table-cell"></span>
              </div>
            </div>
            <div v-for="(item, key) in userUnitlist" :key="key" class="table-row">
              <span>{{ item.name }}</span>
              <span class="pl-1" @click="deleteUnit(item)" v-if="!selfedit">&#10007;</span>
            </div>

            <div class="pr-2">
              <div class="table-row-group font-semibold" v-if="!selfedit">
                <div class="table-row">
                  <span class="table-cell">{{ $t("admin.user.unit.add_units") }}</span>
                  <span class="table-cell"></span>
                </div>
              </div>
              <div>
                <form @submit.prevent="addUnit(save)" action="#" method="POST" autocomplete="off">
                  <cm-search-select
                    v-if="!selfedit"
                    :options="otherUnitslist"
                    ref="add_unit"
                    name="name"
                    valueProp="id"
                    :selected="add_unit"
                    @select="add_unit = $event"
                    :placeholder="$t('admin.user.unit.select')"
                  />
                  <div class="pb-2 flex justify-end space-x-2 mt-2 pt-2">
                    <cm-button v-if="!selfedit" :disabled="!add_unit" type="submit" visual="success">
                      {{ $t("button.add") }}
                    </cm-button>
                    <cm-button
                      @click.prevent="$emit('cancel')"
                      type="button"
                      class="flex justify-center py-2 px-4 border border-gray-200 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:border-gray-300 focus:ring-gray active:bg-gray-100 transition duration-150 ease-in-out"
                    >
                      {{ $t("button.cancel") }}
                    </cm-button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div v-if="section === 'activity'" class="bg-white py-2 px-4 space-y-6 sm:p-6">
            <div class="space-y-2">
              <div>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                      <a
                        @click.prevent="viewActivity('patientviews')"
                        href="#"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm"
                        :class="{ 'border-indigo-500 text-indigo-600': activityType === 'patientviews' }"
                      >
                        {{ $t("admin.user.patientactivity") }}
                      </a>
                      <a
                        @click.prevent="viewActivity('logins')"
                        href="#"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm"
                        :class="{ 'border-indigo-500 text-indigo-600': activityType === 'logins' }"
                        aria-current="page"
                      >
                        {{ $t("admin.user.loginactivity") }}
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
              <div v-if="activityType === 'patientviews'" class="table w-full">
                <div class="table-row-group font-semibold">
                  <div class="table-row">
                    <span class="table-cell">{{ $t("patient.id") }}</span>
                    <span class="table-cell">{{ $t("patient.first_name") }}</span>
                    <span class="table-cell">{{ $t("patient.last_name") }}</span>
                    <span class="table-cell">{{ $t("date") }}</span>
                    <span class="table-cell"></span>
                  </div>
                </div>
                <div v-for="(item, key) in userpatientviews" :key="key" class="table-row">
                  <span class="table-cell">{{ item.patient.id }}</span>
                  <span class="table-cell">{{ item.patient.first_name }}</span>
                  <span class="table-cell">{{ item.patient.last_name }}</span>
                  <span class="table-cell">{{ formattedDateAndTime(item.event_at) }}</span>
                  <span class="table-cell"
                    ><a href="#" @click="activatePatient(item.patient.id)">{{ $t("link.patient.overview") }}</a></span
                  >
                </div>
              </div>
              <div v-if="activityType === 'logins'" class="table w-full">
                <div class="table-row-group font-semibold">
                  <div class="table-row">
                    <span class="table-cell">{{ $t("timestamp") }}</span>
                    <span class="table-cell">{{ $t("user.login.type") }}</span>
                  </div>
                </div>
                <div v-for="(item, key) in userlogins" :key="key" class="table-row">
                  <span class="table-cell">{{ formattedDateAndTime(item.event_at) }}</span>
                  <span class="table-cell">{{ item.event_data.type || "" }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormat, dateAndTimeFormat } from "@/utils/date";
import { ValidationObserver, ValidationProvider, extend, localize, configure } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

function loadVeeValidateLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

configure({
  generateMessage: localize("sv", {
    messages: {
      password: "Lösenorden stämmer inte överens",
    },
  }),
});

extend("email", { ...email });
extend("regex", { ...regex });

// Override the default message.
extend("required", { ...required });
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  name: "UserEdit",
  props: ["user", "selfedit"],
  data() {
    return {
      loaded: false,
      clicked: false,
      section: "profile",
      activityType: "patientviews",
      userdata: {
        hsa_id: null,
        first_name: null,
        last_name: null,
        email: null,
        mobile_phone: null,
        pid: null,
        main_unit: null,
        password: null,
        current_password: null,
        units: [],
      },
      password_confirm: null,
      add_unit: null,
      userUnits: {},
    };
  },
  created() {
    this.loadActivity();
  },
  beforeMount() {
    loadVeeValidateLocale(this.userLocale ?? "sv");
  },
  computed: {
    ...mapState("admin", {
      unitlist: state => state.unitlist,
      useractivity: state => state.useractivity,
    }),
    otherUnitslist() {
      return this.unitlist.filter(u => !this.userUnits.includes(u.id));
    },
    userUnitlist() {
      return this.user ? this.unitlist.filter(u => this.userUnits.includes(u.id)) : this.unitlist;
    },
    userpatientviews() {
      return this.useractivity[this.user.id].filter(d => d.event_type === "patient.activate" && d.patient);
    },
    userlogins() {
      return this.useractivity[this.user.id]
        .filter(d => d.event_type === "user.login")
        .map(d => {
          d.event_data = d.event_data || "2fa";
          return d;
        });
    },
    passwordRules() {
      return !this.user || this.userdata.password ? "required" : "";
    },
    userFullname() {
      return `${this.userdata.first_name} ${this.userdata.last_name}`;
    },
  },
  methods: {
    setSection(section) {
      this.section = section;
    },
    async loadActivity() {
      if (this.user) {
        this.userdata = JSON.parse(JSON.stringify(this.user));
        this.userUnits = this.userdata.units.map(u => {
          return u.unit.id;
        });

        this.userdata.password = null;
        this.$store.dispatch("admin/getUseractivity", this.user.id).then(() => {
          //noop
        });
      }
      await this.$store.dispatch("admin/getUnitlist");

      this.loaded = true;
    },
    viewActivity(type) {
      this.activityType = type;
    },
    formattedDate(date) {
      return date ? dateFormat(date) : null;
    },
    formattedDateAndTime(date) {
      return date ? dateAndTimeFormat(date) : null;
    },
    activatePatient(id) {
      if (this.clicked) return;

      this.clicked = true;
      this.$store.dispatch("user/activatePatient", id).then(() => {
        this.clicked = false;
      });
    },
    save() {
      if (!this.userdata.password) delete this.userdata["password"];
      if (this.userdata.pid) {
        this.userdata.pid = this.userdata.pid.replace(/\s|-|\+/gi, "");
      }

      this.$store
        .dispatch("admin/saveUser", this.userdata)
        .then(() => {
          this.$emit("cancel");
        })
        .catch(e => {
          if (e.response?.data?.field) {
            this.$refs[e.response.data.field].setErrors([this.$t(e.response.data.message)]); //error messages should start with error.user from backend
          }
        });
    },
    userDelete() {
      let buttons = [
        {
          title: this.$t("button.cancel"),
          handler: () => {
            this.$modal.hide("dialog");
          },
        },
        {
          title: this.$t("button.continue"),
          class: "vue-dialog-button bg-red-500 text-white hover:bg-red-600 transition ease-in-out duration-150",
          handler: () => {
            this.$modal.hide("dialog");
            this.$emit("cancel");
            this.$store.dispatch("admin/deleteUser", this.userdata.id).then(() => {});
          },
        },
      ];

      this.$modal.show("dialog", {
        title: this.$t("admin.user.delete.title"),
        text: this.$t("admin.user.delete.text"),
        buttons: buttons,
      });
    },
    addUnit() {
      this.$store.dispatch("admin/setUserUnit", { id: this.userdata.id, unit_id: this.add_unit });
      this.userUnits.push(this.add_unit);
      this.$set(this, "add_unit", null);

      this.$forceUpdate();
    },
    deleteUnit(unit) {
      let buttons = [
        {
          title: this.$t("button.cancel"),
          handler: () => {
            this.$modal.hide("dialog");
          },
        },
        {
          title: this.$t("button.continue"),
          class: "vue-dialog-button bg-red-500 text-white hover:bg-red-600 transition ease-in-out duration-150",
          handler: () => {
            this.$modal.hide("dialog");
            this.$store.dispatch("admin/deleteUserUnit", { id: this.userdata.id, unit_id: unit.id });
            this.userUnits = this.userUnits.filter(u => u != unit.id);
          },
        },
      ];

      this.$modal.show("dialog", {
        title: this.$t("admin.user.unit.delete.title"),
        text: this.$t("admin.user.unit.delete.text", {
          unit: unit.name,
        }),
        buttons: buttons,
      });
    },
  },
  destroyed() {
    this.userdata = null;
  },
};
</script>

<style>
</style>